/* Author: 劉喆 John Liu
   License: GNU General Public License Version 3 (GPLv3) */

/*@import url("https://fonts.googleapis.com/css2?family=NotoSerifTC&display=swap");*/

@font-face {
  font-family: 'frontfont';
  src: local('frontfont'), 
  url(https://raw.githubusercontent.com/ntpcai/data/main/first/fonts/frontfont.woff2) format('woff2'),
  url(https://raw.githubusercontent.com/ntpcai/data/main/first/fonts/frontfont.ttf) format('truetype');
}

@font-face {
  font-family: 'backfont';
  src: local('backfont'), 
  url(https://raw.githubusercontent.com/ntpcai/data/main/first/fonts/backfont.woff2) format('woff2'),
  url(https://raw.githubusercontent.com/ntpcai/data/main/first/fonts/backfont.ttf) format('truetype');
}

@font-face {
  font-family: 'radicals';
  src: local('radicals'), 
  url(https://raw.githubusercontent.com/ntpcai/data/main/first/fonts/radicals.woff2) format('woff2'),
  url(https://raw.githubusercontent.com/ntpcai/data/main/first/fonts/radicals.ttf) format('truetype');
}


:root {
  --fontFamily: frontfont, Noto, PingFang-TC, serif;
  --border-radius: 20px;
  --grad-one: linear-gradient(#0702a8, #221ce8);
  --grad-two: linear-gradient(#96153e, #e02341);
  --layout-width: 350px;
}

* {
      touch-action: manipulation;
  }

.disable-doubletap-to-zoom {
    touch-action: none;
}

body {
  margin: 20px auto;
  background: #242934;
  font-family: var(--fontFamily);
  color: white;
  touch-action: manipulation;
/*  overflow: hidden;*/
}

.nav1fr {
  width: var(--layout-width);
  margin: auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  touch-action: manipulation;
  border-color: #87898c;
}

.nav2fr {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  touch-action: manipulation;
}



.nav2fr button {
  border: none;
  background: #111122;
  color: white;
  font-size: 20px;
  padding: 0.8em 1.2em;
  border-radius: var(--border-radius);
  margin: 0.6em;
  opacity: 0.85;
  transition: all 0.25s;
  cursor: pointer;
  line-height: 48px;
  display:flex;
  justify-content: center;
  touch-action: manipulation;
}

.nav2fr button img {
  height: 30px;
}

.nav2fr button.up {
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
}

.nav2fr button.up img {
  height: 23px;
}


.nav2fr button.home {
  line-height: 24px;
  background-color: black;
}


.nav2fr button:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.nav2fr button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transform: translateY(0px);
}

.nav2fr button:focus {
  outline: none;
}

.nav3fr {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  touch-action: manipulation;
}

.nav3fr button {
  border: none;
  background: #111122;
  color: white;
  font-size: 1em;
  font-family: frontfont;
  padding: 0.8em 1em;
  border-radius: var(--border-radius);
  margin: 0.6em;
  opacity: 0.85;
  transition: all 0.25s;
  cursor: pointer;
  line-height: 48px;
  touch-action: manipulation;
}


.nav3fr button.short {
  line-height: 24px;
}

.nav3fr button:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.nav3fr button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transform: translateY(0px);
}

.nav3fr button:focus {
  outline: none;
}



.cardNumber {
  color: white;
  display: flex;
  justify-content: center;
}

.loadscreen {
  color: white;
  width: 350px;
  text-align: center;
  font-size: 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
